import { LitElement, html } from "lit";
import { unsafeCSS } from "lit";
import { customElement, property } from "lit/decorators.js";
import { ifDefined } from "lit/directives/if-defined.js";
import { when } from "lit/directives/when.js";
import baseStyles from "../../baseStyles.css";
import hostStyles from "./BuiWatchCard.css";
import "../bui-picture.ts"; // Assuming bui-picture handles the image logic

@customElement("bui-watch-card")
export class BuiWatchCard extends LitElement {
  @property({ type: String }) url = "";
  @property({ type: String }) brand = "Rolex";
  @property({ type: String }) model = "Submariner";
  @property({ type: String, attribute: "line1" }) line1 = "";
  @property({ type: String, attribute: "line2" }) line2 = "";
  @property({ type: String, attribute: "line3" }) line3 = "";
  @property({ type: String }) price = "";

  // Image related properties - Pass relevant ones to bui-picture.ts
  @property({ type: String }) imgCDN = "https://images.bobswatches.com";
  @property({ type: String, attribute: "img-subdomain" }) imgSubdomain = "";
  @property({ type: String }) imgPlaceholder = "/alpinejs/img-placeholder.jpg";
  @property({ type: Number, attribute: "img-effort" }) effort = 2;
  @property({ type: Number, attribute: "img-quality" }) quality = 50;
  @property({ type: String, attribute: "img-fit" }) imgFit = "contain";
  @property({ type: String, attribute: "img-sharpness" }) sh = "true"; // Assuming 'sh' maps to 'sharpness' attribute
  @property({ type: String, attribute: "img-width" }) imgWidth = "";
  @property({ type: String, attribute: "img-height" }) imgHeight = "212";
  @property({ type: String, attribute: "img-loading" }) imgLoading: "eager" | "lazy" = "lazy";
  @property({ type: String, attribute: "img-priority" }) imgPriority = "low"; // Assuming 'fetchpriority' attribute
  @property({ type: String, attribute: "img-src" }) imgSrc = "/images/Used-Rolex-Submariner-126610-Black-Chromalight-Dial-SKU167399.jpg";
  @property({ type: Boolean, attribute: "img-preload" }) imgPreload = false;

  // connectedCallback is not needed if empty
  // connectedCallback(): void {
  //   super.connectedCallback();
  // }

  static styles = [unsafeCSS(baseStyles), unsafeCSS(hostStyles)];

  protected render() {
    // Destructure necessary props
    const { url, imgSrc, imgLoading, imgPriority, imgFit, imgWidth, imgHeight, imgPreload, imgSubdomain, effort, quality, sh, brand, model, line1, line2, line3, price } = this;

    return html`
      <a href=${ifDefined(url || undefined)} class="bui-watch-card">
        <figure>
          <bui-picture img-src=${imgSrc} img-loading=${imgLoading} fetchpriority=${imgPriority} img-fit=${imgFit} img-width=${ifDefined(imgWidth || undefined)} img-height=${imgHeight} img-alt="${brand} ${model}" ?img-preload=${imgPreload} img-effort=${ifDefined(effort || undefined)} img-quality=${ifDefined(quality || undefined)} img-sharpness=${ifDefined(sh === "true" ? true : undefined)} subdomain=${ifDefined(imgSubdomain || undefined)}></bui-picture>
          <figcaption class="bui-watch-card-caption">
            <h3 class="bui-watch-card-h3">
              <span class="bui-watch-card-brand">${brand}</span>
              <span class="bui-watch-card-model">${model}</span>
            </h3>
            ${when(line1, () => html`<span class="bui-watch-card-line1">${line1}</span>`)} ${when(line2, () => html`<span class="bui-watch-card-line2">${line2}</span>`)} ${when(line3, () => html`<span class="bui-watch-card-line3">${line3}</span>`)} ${when(price, () => html` <div class="bui-watch-card-price">$${price}</div> `)}
          </figcaption>
        </figure>
      </a>
    `;
  }
}

declare global {
  interface HTMLElementTagNameMap {
    "bui-watch-card": BuiWatchCard;
  }
}
